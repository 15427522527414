<template>
    <div class="artice_top widthCen content">
        <h6>
            <span
                @click="$router.push('/help')"
                style="color:#333"
            >帮助中心</span> >
            <span>文章详情</span>
        </h6>
        <div class="article_white">
            <div class="details">
                <h6>{{model.Title}}</h6>
                <p>
                    类型:
                    <span>{{type}}</span>
                </p>
                <div
                    class="details_box"
                    v-if="model.Url"
                >
                    <video
                        style="width:100%"
                        :src="model.Url"
                        controls="controls"
                    ></video>
                </div>
                <div
                    class="details_text"
                    v-html="model.Content"
                ></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/styles/public.scss';
@import '@/styles/artice.scss';
</style>
<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            model: {
                Title: '',
                Content: '',
                Url: ''
            },
            type: '',
            host: process.env.VUE_APP_BASE_API
        };
    },
    created() {
        var id = this.$route.query.id;
        this.type = this.$route.query.type;
        this.getDetail(id);
    },
    methods: {
        getDetail(id) {
            this.$api.getHelpInfo(id).then(res => {
                this.model = res.data;
            });
        }
    }
};
</script>
